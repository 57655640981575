import { ApartmentOutlined, BankOutlined, CaretDownFilled, CreditCardOutlined, DollarCircleOutlined, QuestionCircleOutlined, SearchOutlined, ShopOutlined } from "@ant-design/icons"
import { APP_PREFIX_PATH, SETTINGS_PREFIX_PATH } from "configs/AppConfig"
import React from "react"

const NavConfig = [
  {
    title: (
      <div className='submenu-wrapper'>
        Envíos
        <CaretDownFilled className="down-icon" />
      </div>
    ),
    key: "envios",
    children: [
      {
        title: "Cotizar un envío",
        icon: <DollarCircleOutlined />,
        path: APP_PREFIX_PATH + "/cotizar-envio"
      },
      {
        title: "Seguir mi envío",
        icon: <SearchOutlined />,
        path: "https://tracking.credifin.com.ar/"
      },
      {
        title: "Ver mis envíos",
        icon: <ApartmentOutlined />,
        path: SETTINGS_PREFIX_PATH + "/mis-envios"
      },
    ]
  },
  {
    title: "Servicios",
    key: "servicios",
    icon: <CreditCardOutlined />,
    path: APP_PREFIX_PATH + "/servicios"
  },
  {
    title: "Sucursales",
    key: "sucursales",
    icon: <ShopOutlined />,
    path: APP_PREFIX_PATH + "/sucursales"
  },
  {
    title: "La Empresa",
    key: "la-empresa",
    icon: <BankOutlined />,
    path: APP_PREFIX_PATH + "/la-empresa"
  },
  {
    title: "Preguntas Frecuentes",
    key: "preguntas-frecuentes",
    icon: <QuestionCircleOutlined />,
    path: APP_PREFIX_PATH + "/faqs"
  },
]

export default NavConfig