import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';

const ClientViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path='/cliente/crear' component={lazy(() => import(`./crear`))} />
      </Switch>
    </Suspense>
  )
}



export default ClientViews;
