export const SET_LOCALIDAD_ORIGEN = "SET_LOCALIDAD_ORIGEN"
export const SET_LOCALIDAD_DESTINO = "SET_LOCALIDAD_DESTINO"
export const COTIZACIONES = "COTIZACIONES"
export const FETCH_COTIZACIONES = "FETCH_COTIZACIONES"
export const FETCH_COTIZACIONES_SUCCESSFUL = "FETCH_COTIZACIONES_SUCCESSFUL"
export const COTIZACION_SELECCIONADA = 'COTIZACION_SELECCIONADA';
export const SET_COTIZACION_SELECCIONADA = 'SET_COTIZACION_SELECCIONADA';
export const REMITENTE = 'REMITENTE';
export const SET_REMITENTE = 'SET_REMITENTE';
export const DESTINATARIO = 'DESTINATARIO';
export const SET_DESTINATARIO = 'SET_DESTINATARIO';
export const QUIERE_NUEVO_REMITENTE = "QUIERE_NUEVO_REMITENTE"
export const QUIERE_NUEVO_DESTINATARIO = "QUIERE_NUEVO_DESTINATARIO"
export const SET_QUIERE_NUEVO_REMITENTE = "SET_QUIERE_NUEVO_REMITENTE"
export const SET_QUIERE_NUEVO_DESTINATARIO = "SET_QUIERE_NUEVO_DESTINATARIO"
export const PAQUETE_ELEGIDO = "PAQUETE_ELEGIDO"
export const SET_PAQUETE_ELEGIDO = "SET_PAQUETE_ELEGIDO"
export const DIMENSIONES = "DIMENSIONES"
export const SET_DIMENSIONES = "SET_DIMENSIONES"
export const SET_TERMINOS_ACEPTADOS = "SET_TERMINOS_ACEPTADOS"
export const SET_HACER_ENVIO_ACTIVE_KEY = "SET_HACER_ENVIO_ACTIVE_KEY"

export const SET_ENVIOS_LOADING = "SET_ENVIOS_LOADING"
