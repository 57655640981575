import antdEsAR from 'antd/es/locale/es_ES';
import enMsg from "../locales/es_AR.json";

const EnLang = {
  antd: antdEsAR,
  locale: 'es-AR',
  messages: {
    ...enMsg
  },
};
export default EnLang;
