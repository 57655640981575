import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Envios from './Envios';
import Domicilios from "./Domicilios"
import MisEnvios from "./MisEnvios"
import EnvioInfo from './EnvioInfo';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Envios(),
    Domicilios(),
    MisEnvios(),
    EnvioInfo()
  ]);
}
