import Loading from 'components/shared-components/Loading';
import { TIENDANUBE_PREFIX_PATH } from 'configs/AppConfig';
import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export const TiendanubeViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${TIENDANUBE_PREFIX_PATH}/login`} component={lazy(() => import(`./login`))} />
        <Route path={`${TIENDANUBE_PREFIX_PATH}/register`} component={lazy(() => import(`./register`))} />
        <Route path={`${TIENDANUBE_PREFIX_PATH}/cuit`} component={lazy(() => import(`./cuit`))} />
        <Route path={`${TIENDANUBE_PREFIX_PATH}/datos-fiscales`} component={lazy(() => import(`./datos-fiscales`))} />
        <Redirect from={`${TIENDANUBE_PREFIX_PATH}`} to={`${TIENDANUBE_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}
export default React.memo(TiendanubeViews);