import React from 'react'
import { Switch, Route, } from "react-router-dom";
import ClientViews from 'views/cliente-views';

export const ClienteLayout = () => {
	return (
		<div className="auth-container">
			<Switch>
				<Route path="" component={ClientViews} />
			</Switch>
		</div>
	)
}

export default ClienteLayout
