import 'isomorphic-fetch';
import { API_BASE_URL } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'redux/constants/Auth';

const options = (method, data) => {
    let opt = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'AUTHORIZATION': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`
        }
    };
    if (data) {
        opt.body = JSON.stringify(data);
    }
    return opt;
};

const api = {
    franquicias: {
        async getAll(token) {
            const response = await fetch("https://api.credifin.com.ar/api/franquicias?web=NO", {
                headers: {
                    "Authorization": token
                }
            })
            const responseData = await response.json();
            return responseData;
        },
        async web(token) {
            const response = await fetch("https://api.credifin.com.ar/api/franquicias/web?web=NO", {
                method: 'GET',
                headers: { 'AUTHORIZATION': token },
            });
            const data = await response.json();
            return data;
        },
    },
    token: {
        async get() {
            const response = await fetch("https://auth.credifin.com.ar/v1/token")
            const responseData = await response.json();
            return responseData;
        }
    },
    auth: {
        async login(data) {
            const response = await fetch(API_BASE_URL + '/authenticated', options('POST', data));
            const responseData = await response.json();
            return responseData;
        },
        async firstLogin(data) {
            const response = await fetch(API_BASE_URL + '/firstlogin', options('POST', data));
            const responseData = await response.json();
            return responseData;
        },
        async getAuthenticatedUser() {
            const response = await fetch(API_BASE_URL + '/authenticated', options('GET'));
            const data = await response.json();
            return data;
        },
        async changePass(data) {
            const response = await fetch(API_BASE_URL + '/changepass', options('POST', data));
            const responseData = await response.json();
            return responseData;
        },
    },
    usuario: {
        async get() {
            const response = await fetch(API_BASE_URL + "/usuario", options("GET"))
            const responseData = await response.json()
            return responseData
        },
        async verificarTelefono(data) {
            const response = await fetch(API_BASE_URL + "/telefono/verificar", options("POST", data))
            const responseData = await response.json()
            return responseData
        },
        async put(data) {
            const response = await fetch(API_BASE_URL + "/usuario", options("PUT", data))
            const responseData = await response.json()
            return responseData
        },
        async verificarCodigoTelefono(codigo) {
            const response = await fetch(API_BASE_URL + "/telefono/chk/" + codigo, options("POST"))
            const responseData = await response.json()
            return responseData
        },
        async verificarEmail(data) {
            const response = await fetch(API_BASE_URL + "/mail/verificar", options("POST", data))
            const responseData = await response.json()
            return responseData
        },
        async verificarCodigoEmail(codigo) {
            const response = await fetch(API_BASE_URL + "/mail/chk/" + codigo, options("POST"))
            const responseData = await response.json()
            return responseData
        }
    },
    cotizar: {
        async post(data, token) {
            const response = await fetch("https://api.credifin.com.ar/api/cotizar", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': token,
                },
            })
            const responseData = await response.json()
            return responseData
        }
    },
    cliente: {
        async create(data) {
            const response = await fetch(API_BASE_URL + "/cliente/create", options("POST", data))
            const responseData = await response.json()
            return responseData
        },
        async put(data) {
            const response = await fetch(API_BASE_URL + "/cliente", options("PUT", data))
            const responseData = await response.json()
            return responseData
        },
    },
    domicilio: {
        async create(data) {
            const response = await fetch(API_BASE_URL + "/domicilio/create", options("POST", data))
            const responseData = await response.json()
            return responseData
        },
        async delete(id) {
            const response = await fetch(API_BASE_URL + `/domicilio/${id}`, options("DELETE"))
            const responseData = await response.json()
            return responseData
        },
    },

    provincias: {
        async getAll() {
            const response = await fetch(API_BASE_URL + `/provincias`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
            });
            const data = await response.json();
            return data;
        },
    },
    token: {
        async get() {
            const response = await fetch("https://auth.credifin.com.ar/v1/token")
            const responseData = await response.json()
            return responseData
        }
    },
    localidades: {
        async getAll(params = '', token) {
            const response = await fetch(API_BASE_URL + '/localidades?' + params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': token,
                },
            });
            const data = await response.json();
            return data;
        },
        async get(id, token) {
            const response = await fetch(API_BASE_URL + `/localidad/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': token,
                },
            });
            const data = await response.json();
            return data;
        },
    },
    user: {
        async upload() {
            return API_BASE_URL + '/usuarios/upload'
        },

        async uQpload(form) {
            const response = await fetch(API_BASE_URL + '/usuarios/upload', {
                method: 'POST',
                headers: {
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: form
            });
            const data = await response.json();
            return data;

        },
        async changePassword(id, data) {
            const response = await fetch(API_BASE_URL + '/password/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async update(data) {
            const response = await fetch(API_BASE_URL + '/usuarios', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async updateprofile(data) {
            const response = await fetch(API_BASE_URL + '/usuarios/profile', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    usuarios: {
        async create(data) {
            const response = await fetch(API_BASE_URL + '/usuarios', options('POST', data));
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(API_BASE_URL + `/usuarios?${query}`, options('GET'));
            const data = await response.json();
            return data;
        },
        async getAllbyRol(rol) {
            const response = await fetch(API_BASE_URL + `/usuarios/rol/${rol}`, options('GET'));
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(API_BASE_URL + `/usuarios/${id}`, options('GET'));
            const data = await response.json();
            return data;
        },
        async update(data) {
            const response = await fetch(API_BASE_URL + '/usuarios', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async baja(id) {
            const response = await fetch(API_BASE_URL + '/usuarios/baja/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            return responseData;
        },
        async alta(id) {
            const response = await fetch(API_BASE_URL + '/usuarios/alta/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            return responseData;
        },
        async blanquearClave(id, values) {
            const response = await fetch(API_BASE_URL + '/blanqueo/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(values)
            });
            const responseData = await response.json();
            return responseData;
        },
        async cambiarClave(id, values) {
            const response = await fetch(API_BASE_URL + '/password/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(values)
            });
            const responseData = await response.json();
            return responseData;
        }
    },
    instagram: {
        async refreshToken(current_token) {
            const response = await fetch('https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=' + current_token, {
                method: 'GET',
            });
            const data = await response.json();
            return data;
        },
        async getNovedades(fields, token) {
            const url = `https://graph.instagram.com/me/media?fields=` + fields + `&&access_token=` + token;
            const response = await fetch(url)
            const data = await response.json()
            return data
        }
    },
    roles: {
        async create(data) {
            const response = await fetch(API_BASE_URL + '/roles', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(API_BASE_URL + '/roles?' + query, options('GET'));
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(API_BASE_URL + `/roles/${id}`, options('GET'));
            const data = await response.json();
            return data;
        },
        async update(data) {
            const response = await fetch(API_BASE_URL + '/roles', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async delete(id) {
            const response = await fetch(API_BASE_URL + '/roles/' + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    vendedores: {
        async getAll(query = '') {
            const response = await fetch(API_BASE_URL + '/vendedores?' + query, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    clientes: {
        async getAll(query = '') {
            const response = await fetch(API_BASE_URL + '/clientes?' + query, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(API_BASE_URL + '/clientes/' + id, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    misEnvios: {
        async get() {
            const response = await fetch(API_BASE_URL + "/misenvios", options("GET"))
            const data = await response.json()
            return data
        }
    },
    tracking: {
        async get(codigoseguimiento) {
            const url = "https://api.credifin.com.ar/api/track/" + codigoseguimiento
            const response = await fetch(url, options("GET"))
            const data = await response.json()
            return data
        }
    },
    comprobantes: {
        async post(data) {
            const url = API_BASE_URL + "/comprobante"
            const response = await fetch(url, options("POST", data))
            const responseData = await response.json()
            return responseData
        },
        async autorizar(id_autorizacion, id_pago) {
            const url = API_BASE_URL + `/comprobante/autorizado/${id_autorizacion}/${id_pago}`
            const response = await fetch(url, options("GET"))
            const responseData = await response.json()
            return responseData
        }
    },
    imprimir: {
        async etiqueta(id_comprobante) {
            const url = API_BASE_URL + `/comprobantes/${id_comprobante}/etiqueta/PDF`
            const response = await fetch(url, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem(AUTH_TOKEN),
                    'Content-Type': 'application/json',
                }

            })
            const buf = await response.arrayBuffer();
            const file = new File([buf], id_comprobante + '.pdf', { type: "application/pdf" })
            return file;
        },
        async comprobante(id_comprobante) {
            const url = API_BASE_URL + `/comprobantes/${id_comprobante}/imprimir`
            const response = await fetch(url, options("GET"))
            const responseData = await response.json()
            return responseData
        },
    },
    mercadopago: {
        async post(data) {
            const url = API_BASE_URL + "/mercadopago/preferencia"
            const response = await fetch(url, options("POST", data))
            const responseData = await response.json()
            return responseData
        }
    },
    tiendanube: {
        async post(data) {
            const url = API_BASE_URL + "/tiendanube/token"
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + data.token
                },
            })
            const responseData = await response.json()
            return responseData
        }
    },
};


export default api;
