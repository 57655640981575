import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { setEnvioSeleccionado, setMisEnvios } from "redux/actions/MisEnvios";
import { FETCH_ENVIO_SELECCIONADO, FETCH_MIS_ENVIOS } from "redux/constants/MisEnvios";
import api from "services/api";

export function* fetchMisEnvios() {
  yield takeEvery(FETCH_MIS_ENVIOS, function* () {
    try {
      const resp = yield call(api.misEnvios.get)
      console.log(resp);
      if (resp.status === "success") {
        yield put(setMisEnvios(resp.data.envios))
        return
      }
    } catch (error) {
      console.log(error);
    }
  });
}

export function* fetchEnvioSeleccionado() {
  yield takeEvery(FETCH_ENVIO_SELECCIONADO, function* ({ codigoseguimiento }) {
    try {
      const resp = yield call(api.tracking.get, codigoseguimiento)
      console.log(resp);
      yield put(setEnvioSeleccionado(resp.data))
    } catch (error) {
      console.log(error);
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchMisEnvios),
    fork(fetchEnvioSeleccionado),
  ]);
}