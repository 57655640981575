import { FETCH_COTIZACIONES, FETCH_COTIZACIONES_SUCCESSFUL, SET_COTIZACION_SELECCIONADA, SET_DESTINATARIO, SET_DIMENSIONES, SET_ENVIOS_LOADING, SET_HACER_ENVIO_ACTIVE_KEY, SET_LOCALIDAD_DESTINO, SET_LOCALIDAD_ORIGEN, SET_PAQUETE_ELEGIDO, SET_QUIERE_NUEVO_DESTINATARIO, SET_QUIERE_NUEVO_REMITENTE, SET_REMITENTE, SET_TERMINOS_ACEPTADOS } from "redux/constants/Envios";

export const setLocalidadOrigen = (localidadOrigen) => {
  return {
    type: SET_LOCALIDAD_ORIGEN,
    localidadOrigen
  }
};
export const setLocalidadDestino = (localidadDestino) => {
  return {
    type: SET_LOCALIDAD_DESTINO,
    localidadDestino
  }
};
export const fetchCotizaciones = () => {
  return {
    type: FETCH_COTIZACIONES,
  }
};
export const fetchCotizacionesSuccessful = (cotizaciones) => {
  return {
    type: FETCH_COTIZACIONES_SUCCESSFUL,
    cotizaciones
  }
};
export const setCotizacionSeleccionada = (cotizacionSeleccionada) => {
  return {
    type: SET_COTIZACION_SELECCIONADA,
    cotizacionSeleccionada
  }
};
export const setRemitente = (remitente) => {
  return {
    type: SET_REMITENTE,
    remitente
  }
};

export const setDestinatario = (destinatario) => {
  return {
    type: SET_DESTINATARIO,
    destinatario
  }
};
export const setQuiereNuevoRemitente = (quiereNuevoRemitente) => {
  return {
    type: SET_QUIERE_NUEVO_REMITENTE,
    quiereNuevoRemitente
  }
};
export const setQuiereNuevoDestinatario = (quiereNuevoDestinatario) => {
  return {
    type: SET_QUIERE_NUEVO_DESTINATARIO,
    quiereNuevoDestinatario
  }
};
export const setPaqueteElegido = (paqueteElegido) => {
  return {
    type: SET_PAQUETE_ELEGIDO,
    paqueteElegido
  }
};
export const setDimensiones = (dimensiones) => {
  return {
    type: SET_DIMENSIONES,
    dimensiones
  }
};

export const setHacerEnvioActiveKey = (activeKey) => {
  return {
    type: SET_HACER_ENVIO_ACTIVE_KEY,
    activeKey
  }
}

export const setTerminosAceptados = terminosAceptados => {
  return {
    type: SET_TERMINOS_ACEPTADOS,
    terminosAceptados
  }
}

export const setEnviosLoading = loading => {
  return {
    type: SET_ENVIOS_LOADING,
    loading
  }
}