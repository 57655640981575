import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import { useEffect } from "react"
import { lock, unlock } from "tua-body-scroll-lock"

const FullscreenLoading = () => {
  useEffect(() => {
    lock()
    document.body.style.pointerEvents = "none"
    return () => {
      unlock()
      document.body.style.pointerEvents = "auto"
    }
  }, [])
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        backgroundColor: "rgba(0,0,0,0.2)",
        zIndex: "12312"
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 45
              }}
            />
          }
        />
      </div>
    </div>
  )
}

export default FullscreenLoading