import { combineReducers } from 'redux';
import Auth from './Auth';
import Envios from './Envios';
import Theme from './Theme';
import domicilios from './Domicilios';
import misEnvios from './MisEnvios';
import sucursales from './Sucursales';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    envios: Envios,
    domicilios: domicilios,
    misEnvios: misEnvios,
    sucursales: sucursales
});

export default reducers;