import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { fetchCotizacionesSuccessful } from "redux/actions/Envios";
import { FETCH_COTIZACIONES } from "redux/constants/Envios";
import api from "services/api";

export function* getCotizaciones() {
  yield takeEvery(FETCH_COTIZACIONES, function* () {
    const state = yield select()
    const {
      paqueteElegido,
      dimensiones,
      localidadOrigen,
      localidadDestino,
    } = state.envios

    const data = {
      acuerdos_id: 0,
      articulos_id: paqueteElegido.articulos_id,
      bultos: [
        {
          cantidad: parseInt(dimensiones.cantidadDeBultos),
          peso: parseInt(dimensiones.peso),
          valor_declarado: parseInt(dimensiones.valorDeclarado),
          x: parseInt(dimensiones.largo),
          y: parseInt(dimensiones.ancho),
          z: parseInt(dimensiones.largo)
        }
      ],
      dpostal: localidadDestino.cp,
      opostal: localidadOrigen.cp
    }

    try {
      const { token } = yield call(api.token.get)
      const resp = yield call(api.cotizar.post, data, token)
      yield put(fetchCotizacionesSuccessful(resp.data.cotizacion_web))
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(getCotizaciones),
  ]);
}
