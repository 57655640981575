import Loading from 'components/shared-components/Loading';
import { SETTINGS_PREFIX_PATH } from 'configs/AppConfig';
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

export const SettingsViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${SETTINGS_PREFIX_PATH}/perfil`} component={lazy(() => import(`./perfil`))} />
        <Route path={`${SETTINGS_PREFIX_PATH}/mis-envios`} component={lazy(() => import(`./mis-envios`))} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(SettingsViews);