import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { CREATE_DOMICILIO, DELETE_DOMICILIO, FETCH_DIRECCION_SELECCIONADA, FETCH_LOCALIDADES, FETCH_LOCALIDAD_SELECCIONADA, VISTA_MIS_DOMICILIOS } from "redux/constants/Domicilios";
import api from "services/api";
import queryString from "query-string"
import { setDireccionSeleccionada, setDomicilioVista, setFeedbackMessage, setLatitud, setLoading, setLocalidadSeleccionada, setLocalidades, setLongitud } from "redux/actions/Domicilios";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { fetchUsuario } from "redux/actions/Auth";
import { setDestinatario, setHacerEnvioActiveKey, setRemitente } from "redux/actions/Envios";

export function* fetchLocalidades() {
  yield takeEvery(FETCH_LOCALIDADES, function* ({ input }) {
    const data = queryString.stringify({
      q: input.trim(),
      max: 15,
      exacto: "true",
      atendida: 0
    })
    try {
      const { token } = yield call(api.token.get)
      const res = yield call(api.localidades.getAll, data, token)
      if (res.data.localidades) {
        yield put(setLocalidades(res.data.localidades))
      } else {
        yield put(setLocalidades([]))
      }
    } catch (error) {
      yield put(setLocalidades([]))
    }
  });
}

export function* fetchLocalidadSeleccionada() {
  yield takeEvery(FETCH_LOCALIDAD_SELECCIONADA, function* ({ id }) {
    try {
      const { token } = yield call(api.token.get)
      const res = yield call(api.localidades.get, id, token)
      yield put(setLocalidadSeleccionada(res.data.localidad_ar))
      yield put(setDireccionSeleccionada({}))
      yield put(setLatitud(null))
      yield put(setLongitud(null))
    } catch (error) {

    }
  });
}

export function* fetchDireccionSeleccionada() {
  yield takeEvery(FETCH_DIRECCION_SELECCIONADA, function* ({ direccionSeleccionada }) {
    const results = yield call(getGeocode, { address: direccionSeleccionada })
    const result = results[0]
    const { lat, lng } = getLatLng(result)
    let calle
    let altura
    result.address_components.forEach(({ long_name, types }) => {
      if (types.indexOf("route") > -1) {
        calle = long_name
      }
      if (types.indexOf("street_number") > -1) {
        altura = long_name
      }
    });
    const direccion = {
      calle,
      altura,
    }
    yield put(setDireccionSeleccionada(direccion))
    yield put(setLatitud(lat))
    yield put(setLongitud(lng))
  });
}

export function* createDomicilio() {
  yield takeEvery(CREATE_DOMICILIO, function* ({ formData, additionalAction, localidad }) {
    const state = yield select()
    const { latitud, longitud } = state.domicilios

    const body = {
      "id": 0,
      "domiciliotipos_id": formData.domiciliotipos_id,
      "calle": formData.calle,
      "altura": formData.altura,
      "piso": formData.piso,
      "depto": formData.depto,
      "localidad": localidad.localidad,
      "cp": formData.cp,
      "contacto": formData.contacto,
      "telefono": formData.telefono,
      "mail": formData.mail,
      "provincias_id": parseInt(localidad.provincia_id),
      "localidades_ar_id": parseInt(localidad.localidad_id),
      "longitud": longitud.toString(),
      "latitud": latitud.toString()
    }

    console.log({body, localidad});

    yield put(setLoading(true))
    try {
      const resp = yield call(api.domicilio.create, body)
      yield put(fetchUsuario())
      yield put(setDomicilioVista(VISTA_MIS_DOMICILIOS))
      yield put(setFeedbackMessage({
        type: "success",
        message: "Domicilio creado con éxito"
      }))
      yield put(setLoading(false))

      if (additionalAction === "set_remitente") {
        yield put(setRemitente(resp.data.domicilio))
        yield put(setHacerEnvioActiveKey(1))
      }
      if (additionalAction === "set_destinatario") {
        yield put(setDestinatario(resp.data.domicilio))
        yield put(setHacerEnvioActiveKey(2))
      }
      console.log(resp);
    } catch (error) {
      yield put(setFeedbackMessage({
        type: "error",
        message: "No se pudo crear el domicilio"
      }))
      yield put(setLoading(false))
      console.log(error);
    }
  });
}

export function* deleteDomicilio() {
  yield takeEvery(DELETE_DOMICILIO, function* ({ id }) {
    try {
      const resp = yield call(api.domicilio.delete, id)
      yield put(fetchUsuario())
      yield put(setFeedbackMessage({
        type: "success",
        message: "Domicilio borrado con éxito"
      }))
      console.log(resp);
    } catch (error) {
      console.log(error);
      yield put(setFeedbackMessage({
        type: "error",
        message: "No se pudo borrar el domicilio"
      }))
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchLocalidades),
    fork(fetchLocalidadSeleccionada),
    fork(fetchDireccionSeleccionada),
    fork(createDomicilio),
    fork(deleteDomicilio),
  ]);
}
