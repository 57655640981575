export const SET_DOMICILIO_VISTA = "SET_DOMICILIO_VISTA"
export const VISTA_MIS_DOMICILIOS = "VISTA_MIS_DOMICILIOS"
export const VISTA_NUEVO_DOMICILIO = "VISTA_NUEVO_DOMICILIO"

export const FETCH_LOCALIDADES = "FETCH_LOCALIDADES"
export const SET_LOCALIDADES = "SET_LOCALIDADES"
export const FETCH_LOCALIDAD_SELECCIONADA = "FETCH_LOCALIDAD_SELECCIONADA"
export const SET_LOCALIDAD_SELECCIONADA = "SET_LOCALIDAD_SELECCIONADA"

export const FETCH_DIRECCIONES = "FETCH_DIRECCIONES"
export const SET_DIRECCIONES = "SET_DIRECCIONES"
export const FETCH_DIRECCION_SELECCIONADA = "FETCH_DIRECCION_SELECCIONADA"
export const SET_DIRECCION_SELECCIONADA = "SET_DIRECCION_SELECCIONADA"

export const CREATE_DOMICILIO = "CREATE_DOMICILIO"
export const DELETE_DOMICILIO = "DELETE_DOMICILIO"

export const SET_LATITUD = "SET_LATIDUD"
export const SET_LONGITUD = "SET_LONGITUD"

export const SET_LOADING = "SET_LOADING"
export const SET_FEEDBACK_MESSAGE = "SET_FEEDBACK_MESSAGE"