import { Grid, Layout } from 'antd';
import FullscreenLoading from 'components/credifin/FullscreenLoading';
import Loading from 'components/shared-components/Loading';
import React, { Suspense } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import AppViews from 'views/app-views';
import DesktopNavbar from './components/DesktopNavbar/DesktopNavbar';
import MobileNavbar from './components/MobileNavbar/MobileNavbar';
import Footer from './components/Footer/Footer';
const { Content } = Layout;
LoadingOverlay.propTypes = undefined
const AppLayout = ({ loading }) => {
  const screens = Grid.useBreakpoint()

  return (
    <Suspense fallback={<Loading cover="page" />}>
      {loading &&
        <FullscreenLoading />
      }
      <Layout
        style={{
          minHeight: "150vh",
        }}
      >
        {screens.lg ?
          <DesktopNavbar /> :
          <MobileNavbar />
        }
        <Content>
          <AppViews />
        </Content>
        <Footer />
      </Layout>
    </Suspense>
  );
}

const mapStateToProps = ({ envios }) => {
  const { loading } = envios
  return { loading }
}

export default connect(mapStateToProps)(React.memo(AppLayout))