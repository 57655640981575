import { FETCH_COTIZACIONES, FETCH_COTIZACIONES_SUCCESSFUL, SET_COTIZACION_SELECCIONADA, SET_DESTINATARIO, SET_DIMENSIONES, SET_ENVIOS_LOADING, SET_HACER_ENVIO_ACTIVE_KEY, SET_LOCALIDAD_DESTINO, SET_LOCALIDAD_ORIGEN, SET_PAQUETE_ELEGIDO, SET_QUIERE_NUEVO_DESTINATARIO, SET_QUIERE_NUEVO_REMITENTE, SET_REMITENTE, SET_TERMINOS_ACEPTADOS } from "redux/constants/Envios"

const initState = {
  localidadOrigen: {
/*     "id": 3548,
    "localidad_id": "82049180000",
    "localidad": "RECONQUISTA",
    "provincia_id": "20",
    "provincia_nombre": "SANTA FE",
    "centroide_lat": "-29.1451468389264",
    "centroide_lon": "-59.6510730563582",
    "franquicias_id": 10,
    "redespacho": false,
    "cp": "3560",
    "mapa": true,
    "zoom": 13 */
  },
  localidadDestino: {
/*     "id": 3588,
    "localidad_id": "82063170000",
    "localidad": "SANTA FE",
    "provincia_id": "20",
    "provincia_nombre": "SANTA FE",
    "centroide_lat": "-31.645164805431",
    "centroide_lon": "-60.7093147118987",
    "franquicias_id": 14,
    "redespacho": false,
    "cp": "3000",
    "mapa": false,
    "zoom": 0 */
  },
  cotizaciones: [],
  cotizacionSeleccionada: {
/*     "id": 1,
    "descripcion": "Domicilio a Domicilio",
    "precio": 3954.21,
    "precio_final": 4784.59,
    "flete": 3704.21,
    "seguro": 250 */
  },
  destinatario: {},
  remitente: {},
  quiereNuevoRemitente: false,
  quiereNuevoDestinatario: false,
  paqueteElegido: {
    text: "Bulto",
    articulos_id: 0,
  },
  dimensiones: {},
  hacerEnvioActiveKey: 0,
  cotizacionesLoading: false,
  terminosAceptados: false,
  loading: false
}

const envios = (state = initState, action) => {
  switch (action.type) {
    case SET_PAQUETE_ELEGIDO:
      return {
        ...state,
        paqueteElegido: action.paqueteElegido,
        cotizaciones: []
      }
    case SET_LOCALIDAD_ORIGEN:
      return {
        ...state,
        localidadOrigen: action.localidadOrigen,
        cotizaciones: []
      }
    case SET_LOCALIDAD_DESTINO:
      return {
        ...state,
        localidadDestino: action.localidadDestino,
        cotizaciones: []
      }
    case SET_DIMENSIONES:
      return {
        ...state,
        dimensiones: action.dimensiones,
        cotizaciones: []
      }
    case FETCH_COTIZACIONES:
      return {
        ...state,
        cotizacionesLoading: true,
      }
    case FETCH_COTIZACIONES_SUCCESSFUL:
      return {
        ...state,
        cotizaciones: action.cotizaciones,
        cotizacionesLoading: false
      }
    case SET_COTIZACION_SELECCIONADA:
      return {
        ...state,
        cotizacionSeleccionada: action.cotizacionSeleccionada
      }
    case SET_REMITENTE:
      return {
        ...state,
        remitente: action.remitente
      }
    case SET_DESTINATARIO:
      return {
        ...state,
        destinatario: action.destinatario
      }
    case SET_QUIERE_NUEVO_REMITENTE:
      return {
        ...state,
        quiereNuevoRemitente: action.quiereNuevoRemitente,
        remitente: {}
      }
    case SET_QUIERE_NUEVO_DESTINATARIO:
      return {
        ...state,
        quiereNuevoDestinatario: action.quiereNuevoDestinatario,
        destinatario: {}
      }
    case SET_HACER_ENVIO_ACTIVE_KEY:
      return {
        ...state,
        hacerEnvioActiveKey: action.activeKey
      }
    case SET_TERMINOS_ACEPTADOS:
      return {
        ...state,
        terminosAceptados: action.terminosAceptados
      }
    case SET_ENVIOS_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    default:
      return state;
  }
}

export default envios