import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'


export const APP_NAME = 'Credifín';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const API_STATIC_URL = env.API_ENDPOINT_STATIC
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const TIENDANUBE_PREFIX_PATH = '/tiendanube';
export const CLIENTE_PREFIX_PATH = '/cliente';
export const SETTINGS_PREFIX_PATH = '/settings';
export const URL_SOCKET_API = 'https://socket.credifin.com.ar';

export const THEME_CONFIG = {
	navCollapsed: false,
	rightNavCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'es',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#2a3542',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	rightNavCollapsedType: ""
};

