import { CREATE_DOMICILIO, DELETE_DOMICILIO, FETCH_DIRECCIONES, FETCH_DIRECCION_SELECCIONADA, FETCH_LOCALIDADES, FETCH_LOCALIDAD_SELECCIONADA, SET_DIRECCIONES, SET_DIRECCION_SELECCIONADA, SET_DOMICILIO_VISTA, SET_FEEDBACK_MESSAGE, SET_LATITUD, SET_LOADING, SET_LOCALIDADES, SET_LOCALIDAD_SELECCIONADA, SET_LONGITUD } from "redux/constants/Domicilios";

export const setDomicilioVista = (domicilioVista) => {
  return {
    type: SET_DOMICILIO_VISTA,
    domicilioVista
  }
}
export const fetchLocalidades = (input) => {
  return {
    type: FETCH_LOCALIDADES,
    input
  }
}
export const fetchDirecciones = (input) => {
  return {
    type: FETCH_DIRECCIONES,
    input
  }
}
export const setLocalidades = (localidades) => {
  return {
    type: SET_LOCALIDADES,
    localidades
  }
}
export const setDirecciones = (direcciones) => {
  return {
    type: SET_DIRECCIONES,
    direcciones
  }
}

export const fetchLocalidadSeleccionada = (id) => {
  return {
    type: FETCH_LOCALIDAD_SELECCIONADA,
    id
  }
};
export const fetchDireccionSeleccionada = (direccionSeleccionada) => {
  return {
    type: FETCH_DIRECCION_SELECCIONADA,
    direccionSeleccionada
  }
};
export const setLocalidadSeleccionada = (localidadSeleccionada) => {
  return {
    type: SET_LOCALIDAD_SELECCIONADA,
    localidadSeleccionada
  }
};

export const setDireccionSeleccionada = (direccionSeleccionada) => {
  return {
    type: SET_DIRECCION_SELECCIONADA,
    direccionSeleccionada
  }
};

export const setLatitud = (latitud) => {
  return {
    type: SET_LATITUD,
    latitud
  }
};
export const setLongitud = (longitud) => {
  return {
    type: SET_LONGITUD,
    longitud
  }
};

export const createDomicilio = (
  formData,
  additionalAction,
  localidad
) => {
  return {
    type: CREATE_DOMICILIO,
    formData,
    additionalAction,
    localidad
  }
};

export const deleteDomicilio = (id) => {
  return {
    type: DELETE_DOMICILIO,
    id
  }
};

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    loading
  }
}
export const setFeedbackMessage = (feedbackMessage) => {
  return {
    type: SET_FEEDBACK_MESSAGE,
    feedbackMessage
  }
}
