import { Menu } from "antd"
import { Link, useLocation } from "react-router-dom"
import NavConfig from "./Home/NavConfig"
import { APP_PREFIX_PATH } from "configs/AppConfig"
import { HomeOutlined } from "@ant-design/icons"

const { Item } = Menu
export default function SideNavMenu() {
  const location = useLocation()
  return (
    <Menu
      mode="inline"
      style={{ border: "none" }}
      defaultSelectedKeys={[location.pathname]}
    >
      <Item key={APP_PREFIX_PATH + "/home"} icon={<HomeOutlined />} title="Inicio">
        <Link to={APP_PREFIX_PATH + "/home"}>
          Inicio
        </Link>
      </Item>
      {NavConfig.map(item => {
        if (item.children) {
          return item.children.map(child => {
            return (
              <Item key={child.path} icon={child.icon} title={child.title}>
                {child.path.includes("https") ?
                  <a href={child.path}>
                    {child.title}
                  </a> :
                  <Link to={child.path}>
                    {child.title}
                  </Link>
                }
              </Item>
            )
          })
        }
        return (
          <Item key={item.path} icon={item.icon} title={item.title}>
            {item.path.includes("https") ?
              <a href={item.path}>
                {item.title}
              </a> :
              <Link to={item.path}>
                {item.title}
              </Link>
            }
          </Item>
        )
      })}
    </Menu>
  )
}