import "./DesktopNavbarMenu.css"
import { Avatar, Divider, Menu } from 'antd';
import NavConfig from "components/layout-components/Home/NavConfig";
import { useState } from 'react';
import { Link } from "react-router-dom";

const { Item, SubMenu } = Menu
const DesktopNavbarMenu = () => {
  const [current, setCurrent] = useState();
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      className="desktop-menu"
    >
      {NavConfig.map(item => {
        if (item.children) {
          return (
            <SubMenu className="desktop-menu--item text-primary" title={item.title} key={item.key}>
              {item.children.map(child => {
                return (
                  <Item key={child.title}>
                    {/* 
                      Si el enlace es externo, usar anchor tag en vez de link
                    */}
                    {child.path.includes("https") ?
                      <a href={child.path} className="text-primary font-weight-bold" >
                        {child.title}
                      </a> :
                      <Link to={child.path} className="text-primary font-weight-bold" >
                        {child.title}
                      </Link>
                    }
                  </Item>
                )
              })}
            </SubMenu>
          )
        }
        return (
          <Item className="desktop-menu--item" key={item.key}>
            {item.path.includes("https") ?
              <a href={item.path} className="text-primary font-weight-bold" >
                {item.title}
              </a>
              :
              <Link to={item.path} className="text-primary font-weight-bold" >
                {item.title}
              </Link>
            }
          </Item>
        )
      })}
    </Menu>
  )
};
export default DesktopNavbarMenu;

