import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { API_STATIC_URL } from "configs/AppConfig";
import React from "react";

const UserAvatar = ({ user }) => {
  const avatarSrc = user?.avatar ?
    API_STATIC_URL + user.avatar.replace("./static", "") :
    null

  return (
    <Avatar
      size={45}
      src={avatarSrc}
      icon={<UserOutlined />}
    />
  )
}

export default UserAvatar