import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, SETTINGS_PREFIX_PATH, TIENDANUBE_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import ClienteLayout from "layouts/cliente-layout";
import SettingsLayout from "layouts/settings-layout";
import Utils from "utils";
import TiendanubeViews from "./tiendanube-views";

function ClienteRouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
function AppRouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/cliente/crear",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
function SettingsRouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/cliente/crear",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location, direction, user, cliente } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={`${APP_PREFIX_PATH}/home`} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={TIENDANUBE_PREFIX_PATH}>
            <TiendanubeViews />
          </Route>
          <SettingsRouteInterceptor path={SETTINGS_PREFIX_PATH} isAuthenticated={token && !Utils.isObjectEmpty(cliente)} user={user} >
            <SettingsLayout direction={direction} location={location} />
          </SettingsRouteInterceptor>
          <ClienteRouteInterceptor path="/cliente/crear" isAuthenticated={token} user={user}>
            <ClienteLayout direction={direction} location={location} />
          </ClienteRouteInterceptor>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location} />
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}


const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token, user, cliente } = auth;
  return { locale, direction, token, user, cliente }
};

export default withRouter(connect(mapStateToProps)(Views));