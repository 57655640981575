const { SET_SUCURSALES_RESULTS } = require("redux/constants/Sucursales");

const initState = {
  sucursalesResults: []
}
const sucursales = (state = initState, action) => {
  switch (action.type) {
    case SET_SUCURSALES_RESULTS:
      return {
        ...state,
        sucursalesResults: action.payload
      }
    default:
      return state
  }
}

export default sucursales