import "./NavProfile.css"
import { EditOutlined, LogoutOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import UserAvatar from "components/layout-components/UserAvatar";
import Flex from "components/shared-components/Flex";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { setCliente, setUser } from "redux/actions/Auth";
import { signOut } from "redux/actions/Auth";
import { AUTH_CLIENTE, AUTH_USER } from "redux/constants/Auth";
import api from "services/api";

const { Item, SubMenu } = Menu
const NavProfile = ({ user, signOut, setUser, setCliente }) => {
  const history = useHistory()
  const fetchUser = async () => {
    try {
      const userResp = await api.usuario.get()
      if (userResp.status !== "success") {
        signOut()
        return
      }
      setUser(userResp.data.usr)
      setCliente(userResp.data.cliente)

      localStorage.setItem(AUTH_USER, JSON.stringify(userResp.data.usr))
      localStorage.setItem(AUTH_CLIENTE, JSON.stringify(userResp.data.cliente))
      console.log({ userResp });
    } catch (error) {
      signOut()
    }

  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <Menu mode="horizontal" className="nav-profile--menu" triggerSubMenuAction="click" >
      <SubMenu
        className="nav-profile--submenu"
        popupClassName="nav-profile--popup"
        title={
          <UserAvatar user={user} />
        }
      >
        <div className="nav-profile--info">
          <UserAvatar user={user} />
          <Flex flexDirection="column">
            <h4 className="m-0 text-dark font-weight-bold">{user.nombre}</h4>
            <p className="m-0 text-gray-light">Frontend Developer</p>
          </Flex>
        </div>
        <Item>
          <Link to="/settings/perfil">
            <Flex alignItems="center">
              <EditOutlined />
              Editar perfil
            </Flex>
          </Link>
        </Item>
        <Item onClick={() => {
          signOut()
          setTimeout(() => {
            history.push("/auth/login")
          }, 500)
        }
        }>
          <Flex alignItems="center">
            <LogoutOutlined />
            Cerrar sesión
          </Flex>
        </Item>
      </SubMenu>
    </Menu>
  )
}

const mapStateToProps = ({ auth }) => {
  const { user, token } = auth;
  return { user, token }
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => {
      dispatch(signOut());
    },
    setUser: (user) => {
      dispatch(setUser(user));
    },
    setCliente: (cliente) => {
      dispatch(setCliente(cliente));
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavProfile));
