import "./Footer.css"
import { Col, Row } from "antd"
import { Link } from "react-router-dom"
const config = [
  {
    title: "La empresa",
    links: [
      {
        text: "Nuestros servicios",
        href: "#"
      },
      {
        text: "Oportunidades laborales",
        href: "#"
      },
      {
        text: "Quiero abrir una franquicia",
        href: "#"
      },
      {
        text: "Nuestras marcas",
        href: "#"
      },
    ],
  },
  {
    title: "Casa Central",
    links: [
      {
        text: "Tel: +54 (03472) 435-578",
        href: "#"
      },
      {
        text: "+54 (3482) 430-144",
        href: "#"
      },
      {
        text: "Lunes a viernes de 9 a 17hs",
        href: "#"
      },
    ],
  },
  {
    title: "Ayuda",
    links: [
      {
        text: "Preguntas frecuentes",
        href: "#"
      },
      {
        text: "Reclamos: +54 (03482) 424000",
        href: "#"
      },
    ],
  },
  {
    title: "Nuestras redes",
    links: [
      {
        text: "Instagram",
        href: "#"
      },
      {
        text: "Facebook",
        href: "#"
      },
    ]
  },
]
const Footer = () => {
  return (
    <div className="footer--wrapper">
      <div className="footer--container">
        <Row gutter={[24, 24]} align="top">
          <Col xs={24} lg={8} >
            <div className="footer--logo-container">
              <img
                src="/img/credifin-logo-white.png"
                alt="logo"
                style={{
                  maxWidth: "250px"
                }}
              />
            </div>
          </Col>
          {config.map(item => (
            <Col xs={24} sm={12} md={6} lg={4} key={item.title}>
              <div className="footer--item-container">
                <p className="footer--item-title">
                  {item.title}
                </p>
                {item.links.map(link => (
                  <Link to={link.href} key={link.text} >
                    <p className="footer--item-link">
                      {link.text}
                    </p>
                  </Link>
                ))}
              </div>
            </Col>
          ))}
        </Row>
        <Row gutter={24} className="mt-5">
          <Col xs={24} lg={8}>
            <p className="footer--bottom-text">
              Copyright 2022 Credifín Logística. Todos los derechos reservados.
            </p>
          </Col>
          {["Términos Y Condiciones", "Reconquista, Santa Fe, Argentina", "Enacom"].map(item => (
            <Col xs={24} lg={4} key={item}>
              <p className="footer--bottom-text">
                {item}
              </p>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}
export default Footer