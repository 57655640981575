import { SET_DIRECCIONES, SET_DIRECCION_SELECCIONADA, SET_DOMICILIO_VISTA, SET_FEEDBACK_MESSAGE, SET_LATITUD, SET_LOADING, SET_LOCALIDADES, SET_LOCALIDAD_SELECCIONADA, SET_LONGITUD, VISTA_MIS_DOMICILIOS } from "redux/constants/Domicilios";

const initState = {
  domicilioVista: VISTA_MIS_DOMICILIOS,
  localidades: [],
  direcciones: [],
  localidadSeleccionada: {},
  direccionSeleccionada: {},
  latitud: null,
  longitud: null,
  feedbackMessage: {},
  loading: false
}

const domicilios = (state = initState, action) => {
  switch (action.type) {
    case SET_DOMICILIO_VISTA:
      return {
        ...state,
        domicilioVista: action.domicilioVista,
        localidades: [],
        direcciones: [],
        localidadSeleccionada: {},
        direccionSeleccionada: {},
        latitud: null,
        longitud: null,
      }
    case SET_LOCALIDADES:
      return {
        ...state,
        localidades: action.localidades
      }
    case SET_DIRECCIONES:
      return {
        ...state,
        direcciones: action.direcciones
      }
    case SET_LOCALIDAD_SELECCIONADA:
      return {
        ...state,
        localidadSeleccionada: action.localidadSeleccionada
      }
    case SET_DIRECCION_SELECCIONADA:
      return {
        ...state,
        direccionSeleccionada: action.direccionSeleccionada
      }
    case SET_LATITUD:
      return {
        ...state,
        latitud: action.latitud
      }
    case SET_LONGITUD:
      return {
        ...state,
        longitud: action.longitud
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case SET_FEEDBACK_MESSAGE:
      return {
        ...state,
        feedbackMessage: action.feedbackMessage
      }
    default:
      return state;
  }
}

export default domicilios
