/* const FirebaseConfig = {
  apiKey: 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM',
  authDomain: 'emilus.firebaseapp.com',
  databaseURL: 'https://emilus.firebaseio.com',
  projectId: 'emilus',
  storageBucket: 'emilus.appspot.com',
  messagingSenderId: '807555350717',
  appId: '1:807555350717:web:145ba7c21af47203a2f7d4',
  measurementId: 'G-8KE7HJB252'
}; */
const FirebaseConfig = {
  apiKey: "AIzaSyCLmj4YCfZwxabyjRleZBT7w2v0jdzcQLU",
  authDomain: "credifin.firebaseapp.com",
  projectId: "credifin",
  storageBucket: "credifin.appspot.com",
  messagingSenderId: "1051918037575",
  appId: "1:1051918037575:web:7158bf7477fd0f7820b762",
  measurementId: "G-MED6H3MH7C"
};

export default FirebaseConfig

