import {
	AUTH_TOKEN,
	AUTH_USER,
	SETUSER,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
	SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
	AUTH_CLIENTE,
	SETCLIENTE,
	SETREDIRECT,
	HIDE_LOADING,
	SET_PREVIOUS_URL
} from '../constants/Auth';

const initState = {
	loading: false,
	message: '',
	showMessage: false,
	/* Es la url anterior a la de login. Por ejemplo,
		 un usuario puede cotizar su envio sin estar logeado,
		 pero a la hora de hacer el envio se lo manda a la pagina de login.
		 Al tener su url anterior, podemos reenviarlo alli cuando termine de logearse
		 o registrarse
	*/
	previousUrl: '',
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN),
	user: { ...(localStorage.getItem(AUTH_USER) && localStorage.getItem(AUTH_USER) !== "undefined" ? JSON.parse(localStorage.getItem(AUTH_USER)) : "") },
	cliente: { ...(localStorage.getItem(AUTH_CLIENTE) && localStorage.getItem(AUTH_CLIENTE) !== "undefined" ? JSON.parse(localStorage.getItem(AUTH_CLIENTE)) : "") },
}

const auth = (state = initState, action) => {
	console.log(action.type);
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				token: action.token
			}
		case SETUSER:
			return {
				...state,
				user: action.user,
			}
		case SETCLIENTE:
			return {
				...state,
				cliente: action.cliente
			}
		case SETREDIRECT:
			return {
				...state,
				redirect: action.redirect,
				previousUrl: ""
			}
		case SET_PREVIOUS_URL:
			return {
				...state,
				previousUrl: action.previousUrl
			}
		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
			}
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				user: {},
				cliente: {}
			}
		}
		case SIGNUP_SUCCESS: {
			return {
				...state,
				user: action.user
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case HIDE_LOADING: {
			return {
				...state,
				loading: false
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				token: action.token
			}
		}
		default:
			return state;
	}
}

export default auth