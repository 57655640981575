import React from 'react'
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import { connect } from "react-redux";
import utils from 'utils';
import { Grid } from 'antd';
import { withRouter } from 'react-router-dom';
import './styles.scss'

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if (isMobile && !props.mobileLogo) {
    return 0
  }
  if (isNavTop) {
    return 'auto'
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

const getLogo = (props) => {
  const { navCollapsed, logoType, isMobile } = props;
  if (navCollapsed) {
    return '/img/credifin-logo-sm.png'
  }
  return '/img/credifin-logo.png'
}

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
}

const getImage = props => {
  if (props.mobileLogo) {
    return <img
      src={getLogo(props)}
      alt={`${APP_NAME} logo`}
      style={{
        width: "130px"
      }}
    />
  }
  if (props.navCollapsed) {
    return <img
      src={getLogo(props)}
      alt={`${APP_NAME} logo`}
      style={{
        width: "40px",
        display: "block",
        margin: "0 auto"
      }}
    />
  }
  return <img
    src={getLogo(props)}
    alt={`${APP_NAME} logo`}
    style={{
      width: "160px"
    }}
  />
}

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  return (
    <div
      onClick={() => { props.history.push({ pathname: '/app/home' }) }}
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}>
      {getImage(props)}
    </div>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType }
};

export default withRouter(connect(mapStateToProps)(Logo));
