import { all, fork, takeEvery } from "redux-saga/effects";
import { CONNECT_SOCKET } from "redux/constants/EnvioInfo";

export function* connectSocket() {
  yield takeEvery(CONNECT_SOCKET, function* ({ socket }) {
    console.log(socket);
    socket.on("connect", )
  })
}

export default function* rootSaga() {
  yield all([
    fork(connectSocket),
  ]);
}