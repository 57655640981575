import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig.js";

export const AppViews = () => {
  return (
    <Switch>
      <Route exact path={`${APP_PREFIX_PATH}/envio-success`} component={lazy(() => import(`./envio-success.js`))} />
      <Route exact path={`${APP_PREFIX_PATH}/cotizar-envio`} component={lazy(() => import(`./shared-cotizar-envio.js`))} />
      <Route exact path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
      <Route exact path={`${APP_PREFIX_PATH}/servicios`} component={lazy(() => import(`./servicios`))} />
      <Route exact path={`${APP_PREFIX_PATH}/faqs`} component={lazy(() => import(`./faqs`))} />
      <Route exact path={`${APP_PREFIX_PATH}/sucursales`} component={lazy(() => import(`./sucursales`))} />
      <Route exact path={`${APP_PREFIX_PATH}/la-empresa`} component={lazy(() => import(`./la-empresa`))} />
    </Switch>
  )
}

export default React.memo(AppViews);