import { Drawer } from "antd";
import SideNavMenu from "components/layout-components/SideNavMenu";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { Link } from "react-router-dom";
import "./MobileDrawer.css";

const MobileDrawer = ({ visible, onClose }) => {
  return (
    <Drawer
      placement="left"
      visible={visible}
      onClose={onClose}
    >
      <div onClick={onClose}>
        <Link to={`${APP_PREFIX_PATH}/home`} >
          <img
            src='/img/credifin-logo.png'
            alt='credifin-logo'
            width="180px"
            className="ml-3 my-3"
          />
        </Link>
        <SideNavMenu />
      </div>
    </Drawer >
  )
}

export default MobileDrawer