import { UserOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Flex from "components/shared-components/Flex";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Utils from "utils";
import "./DesktopNavbar.css";
import DesktopNavbarMenu from "./DesktopNavbarMenu";
import NavProfile from "components/layout-components/NavProfile";

const DesktopNavbar = ({ user }) => {
  return (
    <header className="desktop-navbar--header ">
      <Link to={`${APP_PREFIX_PATH}/home`}>
        <img
          src='/img/credifin-logo.png'
          alt='credifin-logo'
          width="160px"
        />
      </Link>
      <Flex >
        <DesktopNavbarMenu />
        {Utils.isObjectEmpty(user) ?
          <Link to="/auth/login" className="d-flex align-items-center">
            <Button className="ingresa-button">
              <UserOutlined className="ingresa-button--icon"/>
              INGRESAR
            </Button>
          </Link> :
          <NavProfile />
        }
      </Flex>
    </header>
  )
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user }
};

export default connect(mapStateToProps)(DesktopNavbar)