import "./MobileNavbar.css"
import { MenuOutlined, UserOutlined } from "@ant-design/icons"
import { APP_PREFIX_PATH } from "configs/AppConfig"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Button } from "antd"
import Utils from "utils"
import MobileDrawer from "./MobileDrawer"
import NavProfile from "components/layout-components/NavProfile"

const MobileNavbar = ({ user }) => {
  const [drawerVisible, setDrawerVisible] = useState(false)
  const onDrawerOpen = () => setDrawerVisible(true)
  const onDrawerClose = () => setDrawerVisible(false)
  return (
    <header className="mobile-navbar--header">
      <MobileDrawer
        onClose={onDrawerClose}
        visible={drawerVisible}
      />
      <MenuOutlined className="mobile-navbar--icon" onClick={onDrawerOpen} />
      <Link to={`${APP_PREFIX_PATH}/home`}>
        <img
          src='/img/credifin-logo.png'
          alt='credifin-logo'
          width="160px"
        />
      </Link>

      {Utils.isObjectEmpty(user) ?
        <Link to="/auth/login" className="d-flex align-items-center">
          <Button className="ingresa-button">
            <UserOutlined className="ingresa-button--icon" />
            INGRESAR
          </Button>
        </Link> :
        <div className="mobile-navbar--profile-container">
          <NavProfile />
        </div>
      }
    </header>
  )
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user }
};

export default connect(mapStateToProps)(MobileNavbar)