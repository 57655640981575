const { FETCH_MIS_ENVIOS, SET_MIS_ENVIOS, FETCH_ENVIO_TRACKING, SET_ENVIO_TRACKING, FETCH_ENVIO_SELECCIONADO, SET_ENVIO_SELECCIONADO } = require("redux/constants/MisEnvios");

const initState = {
  envios: [],
  envioSeleccionado: {},
  loading: false
}

const misEnvios = (state = initState, action) => {
  switch (action.type) {
    case FETCH_MIS_ENVIOS:
      return {
        ...state,
        loading: true
      }
    case SET_MIS_ENVIOS:
      return {
        ...state,
        loading:false,
        envios: action.envios
      }
    case FETCH_ENVIO_SELECCIONADO: 
      return {
        ...state,
        loading: true
      }
    case SET_ENVIO_SELECCIONADO:
      return {
        ...state,
        loading: false,
        envioSeleccionado: action.envioSeleccionado
      }
    default:
      return state;
  }
}

export default misEnvios