import { FETCH_ENVIO_SELECCIONADO, FETCH_MIS_ENVIOS, SET_ENVIO_SELECCIONADO, SET_MIS_ENVIOS } from "redux/constants/MisEnvios"

export const fetchMisEnvios = () => {
  return {
    type: FETCH_MIS_ENVIOS,
  }
}

export const setMisEnvios = envios => {
  return {
    type: SET_MIS_ENVIOS,
    envios
  }
}

export const fetchEnvioSeleccionado = codigoseguimiento => {
  return {
    type: FETCH_ENVIO_SELECCIONADO,
    codigoseguimiento
  }
}

export const setEnvioSeleccionado = envioSeleccionado => {
  return {
    type: SET_ENVIO_SELECCIONADO,
    envioSeleccionado
  }
}