import {
  DashboardOutlined,
  HomeOutlined,
  ApartmentOutlined,
  InboxOutlined,
  DollarCircleOutlined,
  ShopOutlined,
  LayoutOutlined,
  QuestionCircleOutlined,
  InstagramOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'app',
    path: `${APP_PREFIX_PATH}/app`,
    title: 'menu',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'home',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'Inicio',
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'ver-envios',
        path: `${APP_PREFIX_PATH}/envios`,
        title: 'Ver mis envíos',
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'hacer-envio',
        path: `${APP_PREFIX_PATH}/hacer-envio`,
        title: 'Hacer un envío',
        icon: InboxOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'cotizar-envio',
        path: `${APP_PREFIX_PATH}/cotizar-envio`,
        title: 'Cotizar un envío',
        icon: DollarCircleOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'sucursales',
        path: `${APP_PREFIX_PATH}/sucursales`,
        title: 'Sucursales',
        icon: ShopOutlined,
        breadcrumb: false,
        submenu: []
      },
/*       {
        key: 'embalaje',
        path: `${APP_PREFIX_PATH}/embalaje`,
        title: 'Comprar embalaje',
        icon: LayoutOutlined,
        breadcrumb: false,
        submenu: []
      }, */
      {
        key: 'faq',
        path: `${APP_PREFIX_PATH}/faq`,
        title: 'Preguntas frecuentes',
        icon: QuestionCircleOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'seguinos',
        path: `${APP_PREFIX_PATH}/seguinos`,
        title: 'Seguinos',
        icon: InstagramOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  },

]



const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
