const dev = {
	API_ENDPOINT_URL: 'http://localhost:5602/api',
	API_ENDPOINT_STATIC: "http://localhost:5602/static"
};

const prod = {
	API_ENDPOINT_URL: 'https://apiwebtest.credifin.com.ar/api',
	API_ENDPOINT_STATIC: "https://apiwebtest.credifin.com.ar/static"
};

const test = {
	API_ENDPOINT_URL: 'http://localhost:7000/api',
	API_ENDPOINT_STATIC: "http://localhost:80/static"
};

// const dev = {
//   API_ENDPOINT_URL: 'http://192.168.10.48:7000/api'
// };

// const prod = {
//   API_ENDPOINT_URL: 'http://192.168.10.48:7000/api'
// };

// const test = {
//   API_ENDPOINT_URL: 'http://192.168.10.48:7000/api'
// };

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
